export default function groupBy(array, predicate) {
  const result = new Map();

  if (!array) return result;

  return array.reduce((accum, item) => {
    const id = predicate(item);
    if (!result.has(id)) return result.set(id, [item]);
    else return result.set(id, result.get(id).concat(item));
  }, new Map());
}

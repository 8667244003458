<script>
  import { onMount } from 'svelte';
  import { Octokit } from '@octokit/rest';
  import SkullIcon from './skull.svg';
  import Results from './Results.svelte';

  let results;

  onMount(async () => {
    const octokit = new Octokit();
    const owner = 'djanowski';
    const repo = 'estafabot';
    const path = 'results.json';
    const contents = await octokit.rest.repos.getContent({
      owner,
      repo,
      path,
    });
    results = JSON.parse(base64Decode(contents.data.content));
  });

  function base64Decode(string) {
    return decodeURIComponent(
      atob(string)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );
  }

  function suggest() {
    const text = `@j4n0s Te sugiero esta marca para Estafabot: `;
    const url = new URL('https://twitter.com/intent/tweet');
    url.searchParams.set('text', text);
    window.open(url);
  }
</script>

<main>
  <header>
    <SkullIcon width={64} />

    <p>
      Las cuentas marcadas con <SkullIcon width={16} />
      parecen estar estafando gente en Twitter.
      <br />
      ¡Ayudanos a reportar las cuentas!
      <br />
      ¿Querés entender cómo funciona la estafa? Leé
      <a
        href="https://twitter.com/jpsaraceno/status/1491562227861762057"
        target="_blank">este hilo</a
      >.
      <br />
      Si te interesa que monitoreemos otras marcas, podés sugerirlas.
      <br />
      <button on:click={suggest}>Sugerir otra marca</button>
    </p>
  </header>

  {#if results}
    <Results {results} />
  {:else}
    <div class="spinner">
      <div class="bounce1" />
      <div class="bounce2" />
      <div class="bounce3" />
    </div>
  {/if}

  <footer>
    Desarrollado por
    <a href="https://twitter.com/j4n0s" target="_blank">@j4n0s</a>
    habiendo peloteado ideas con
    <a href="https://twitter.com/jpsaraceno" target="_blank">@jpsaraceno</a>
    • Código abierto en
    <a href="https://github.com/djanowski/estafabot" target="_blank">GitHub</a>
  </footer>
</main>

<style>
  main {
    padding: 1em;
    max-width: 700px;
    margin: 0 auto;
  }

  header {
    text-align: center;
    line-height: 1.8em;
    margin: 0 0 2em 0;
  }

  button {
    cursor: pointer;
    background: #333;
    color: #fff;
    border-radius: 4px;
    padding: 0.7em 0.8em;
    border-width: 0;
    margin: 0.8em 0 0 0;
  }

  footer {
    padding: 1em 0;
    color: #666;
    text-align: center;
  }

  .spinner {
    margin: 5em auto;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>

<script>
  import groupBy from './util/group_by';
  import VerifiedIcon from './verified.svg';
  import BrandResult from './BrandResult.svelte';

  export let results;

  const groups = groupBy(results, item => item.brand.user.id_str);
  const sortedGroups = [...groups.values()].sort((a, b) =>
    a[0].brand.name.localeCompare(b[0].brand.name)
  );

  function getAvatarURL(user) {
    return user.profile_image_url_https.replace('_normal.', '_400x400.');
  }
</script>

<main>
  {#each sortedGroups as group}
    <BrandResult brand={group[0].brand} results={group} />
  {/each}
</main>

<style>
</style>

<script>
  import groupBy from './util/group_by';
  import VerifiedIcon from './verified.svg';
  import PirateIcon from './pirate.svg';
  import SkullIcon from './skull.svg';

  export let brand;
  export let results;

  const groups = groupBy(results, item => item.user.id_str);

  function getAvatarURL(user) {
    return user.profile_image_url_https.replace('_normal.', '_400x400.');
  }
</script>

<section>
  <header>
    <div class="user">
      <img class="avatar" src={getAvatarURL(brand.user)} />
      <div>
        <span class="brand-name">{brand.name}</span>
        {#if brand.user.verified}
          <VerifiedIcon width={16} />
        {/if}
        <br />
        <span class="screen-name">@{brand.user.screen_name}</span>
      </div>
    </div>
  </header>

  {#each [...groups.values()] as group}
    <div class="result">
      <div class="user">
        <img class="avatar" src={getAvatarURL(group[0].user)} />
        <div>
          <span class="user-name">
            {group[0].user.name}
          </span>
          <SkullIcon class="scam" width={20} />
          <br />
          <span class="screen-name">@{group[0].user.screen_name}</span>

          <br />
          {#each group as result}
            <div class="tweet">
              {result.tweet.full_text}
              <div class="actions">
                <a
                  href={`https://twitter.com/i/web/status/${result.tweet.id_str}`}
                  target="_blank">Ver en Twitter</a
                >
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {/each}
</section>

<style>
  section {
    padding: 1.5em 1.2em;
    border: 1px solid #e0e0e0;
    border-radius: 0.5em;
    margin: 1.2em 0;
    background: #fff;
  }

  header {
  }

  .result {
    border-top: 1px solid #e0e0e0;
    padding-top: 1.2em;
    margin-top: 1.2em;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 2px solid #eee;
    margin-right: 0.5em;
  }

  .user-name,
  .brand-name {
    font-weight: bold;
  }

  .brand-name {
    font-size: 22px;
  }

  .screen-name {
    color: #666;
    line-height: 24px;
  }

  .user {
    display: flex;
  }

  .tweet {
    margin: 1em 0;
  }

  .actions {
    font-size: 14px;
    margin-top: 0.4em;
  }

  .actions a {
    margin-right: 1em;
  }

  :global(.scam) {
    fill: #f00;
  }
</style>
